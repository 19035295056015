<template>
  <Button
    class="btn-catalog"
    variant="catalog"
    block
    iconLeft
    :loading="pendingCategories"
    @click="openSideMenuCatalog"
  >
    <template #icon>
      <Icon name="book" />
    </template>
    {{ $t('booksCatalog') }}
  </Button>
</template>

<script lang="ts" setup>
import { Icon } from '@shared/Icon';
import { Button } from '@shared/Button';

const { openSideMenuCatalog } = useSideNav();

const { t: $t } = useI18n();

const { pendingCategories } = storeToRefs(useCatalogStore());
</script>

<style lang="scss" src="./ButtonCatalog.scss" />
